<template>
    <div class="form-group mb-0">
        <Field
            :id="id"
            :name="name"
            :isRequired="isRequired"
            v-model="value"
        >
            <VueDatePicker
                v-model="value"
                :placeholder="placeholder"
                :locale="$datePickerLocale" 
                :format="format"
                :model-type="modelType"
                :enable-time-picker="enableTimePicker"
            />
        </Field>
        <ErrorMessage
            :name="name"
            class="error-feedback text-xs text-danger"
            role="alert"
        />
    </div>
</template>

<script>
import { ErrorMessage, Field } from 'vee-validate';

export default {
    name: "SoftDateTimePicker",
    components: {
        Field,
        ErrorMessage
    },
    props: {
        size: {
            type: String,
            default: "default",
        },
        success: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        modelValue: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "Type here...",
        },
        type: {
            type: String,
            default: "text",
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enableTimePicker: {
            type: Boolean,
            default: true
        },
        format: {
            type: String,
            default: "dd/MM/yyyy HH:mm"
        },
        modelType: {
            type: String,
            default: "dd/MM/yyyy HH:mm"
        }
    },
  
    emits: ["update:modelValue"],

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            }
        },
    }
}
</script>