import supportsService from "../services/supports.service";
import { parseFormError} from "../services/utils";


const initialState = {
  supports: [],
  loading: false,
  form: {
    open: false,
    supports: null, 
    error: null
  }
}

export const supports = {
  namespaced: true,
  state: initialState,
  actions: {
    async getSupports({ commit }) {
      commit("SET_LOADING", true);
      const response = await supportsService.getSupports();
      commit("SET_SUPPORTS", response);
    },
    startAdding({ commit }) {
      const value = { open: true, supports: null };
      commit("SET_FORM", value);
    },
    async addSupports({ commit, dispatch }, supports) {
      try {
        await supportsService.addSupports(supports);
        dispatch("stopEditing");
        dispatch("getSupports");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, supports) {
      const value = { open: true, supports: supports }; // Usando 'supports' consistentemente
      commit("SET_FORM", value);
    },
    async updateSupports({ commit, dispatch }, supports) {
      try {
        await supportsService.updateSupports(supports);
        dispatch("stopEditing");
        dispatch("getSupports");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, supports: null }; // Usando 'supports' consistentemente
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_SUPPORTS(state, supports) { // Modifique para 'supports'
      state.supports = supports // Modifique para 'supports'
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, supports }) { // Modifique para 'supports'
      state.form.open = open;
      state.form.supports = supports; // Modifique para 'supports'
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.supports = []; // Modifique para 'supports'
      state.loading = false;
      state.form = {
        open: false,
        supports: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.supports.length; // Modifique para 'supports'
    }
  }
}
