<template>
    <tr @click="startEditing">
      <td>{{ healthcareOperator.name }}</td>
      <td>{{ active }}</td>
      <td>{{ created_at }}</td>
    </tr>
  </template>
  
  <script>
  import moment from 'moment';
  
  export default {
    props: ['healthcareOperator'],
    computed: {
      active() {
        return this.healthcareOperator.active ? "Sim" : "Não";
      },
      created_at() {
        return moment(this.healthcareOperator.created_at).format("DD/MM/YYYY HH:mm");
      }
    },
    methods: {
      startEditing() {
        this.$store.dispatch('healthcareOperators/startEditing', this.healthcareOperator);
      }
    }
  }
  </script>
  