import api from "./axios";

export default {
    async getPatients(page = 1, per_page =10) {
        const response = await api.get(`/patients?page=${page}&per_page=${per_page}`);
        return response.data;
    },
    async findPatients(query, page= 1, per_page = 10) {
        const response = await api.get(`/patients`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;
    },
    async addPatient(patient) {
        const response = await api.post("/patients", patient)

        return response.data;
    },
    async updatePatient(patient) {
        const response = await api.put(`/patients/${patient.id}`, patient)

        return response.data;
    },
}