<template>
    <tr @click="startEditing">
        <td>{{ id }}</td>
        <td>{{ created_at }}</td>
        <td>{{ updated_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['exam'],
    data: (instance) => ({
        id: instance.exam.id,
        created_at: moment(instance.exam.created_at).format("DD/MM/YYYY HH:mm"),
        updated_at: moment(instance.exam.updated_at).format("DD/MM/YYYY HH:mm"),
    }),
    methods: {
        startEditing() {
            const data = {
                patientId: this.exam.patient_id,
                exam: this.exam
            }
            this.$store.dispatch('exams/startEditing', data)
        },
    }
}
</script>