import api from "./axios";

export default {
    async getTriages(patientId) {
        const response = await api.get(`/patients/${patientId}/triages`);
        return response.data
    },
    async addTriage(patientId, triage) {
        const response = await api.post(`/patients/${patientId}/triages`, triage)

        return response.data;
    },
    async updateTriage(patientId, triage) {
        const response = await api.put(`/patients/${patientId}/triages`, triage)

        return response.data;
    },
}