<template>
  <div class="form-group mb-0">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <Field
        :id="id"
        :name="name"
        :isRequired="isRequired"
        v-model="value"
      >
        <multiselect
          v-model="value"
          :class="getClasses(size, success, error)"
          :placeholder="placeholder"
          :options="internOptions"
          tagPlaceholder="Aperte enter para adicionar"
          selectLabel="Aperte enter para selecionar"
          selectedLabel="Selecionado"
          :showNoOptions="false"
          deselectLabel="Aperte enter para remover"
          multiple
          taggable
          @tag="addTag"
        />
      </Field>
      <ErrorMessage
        :name="name"
        class="error-feedback text-xs text-danger"
        role="alert"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from 'vee-validate';
import Multiselect from 'vue-multiselect';

export default {
  name: "SoftSelectMultiple",
  components: {
    Multiselect,
    Field,
    ErrorMessage
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default() {
        return []
      },
    },
    placeholder: {
      type: String,
      default: "Selecione",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      internValue: [],
      internOptions: this.options 
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.internValue = value
        this.$emit('update:modelValue', value) 
      }
    }
  },
  watch: {
    options(newOptions) {
      this.internOptions = newOptions
    }
  },
  methods: {
    addTag (newTag) {
      this.internOptions.push(newTag)
      this.internValue.push(newTag)
      this.$emit('update:modelValue', this.internValue) 
    },
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
};
</script>