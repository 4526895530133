const CompanyTypes = {
    SUPPLIER: 1,
    CLIENT: 2,
    PARTNER: 3,
    CLINIC: 4
}

export const CompanyTypesName = {
    1: "Fornecedor",
    2: "Cliente",
    3: "Parceiro",
    4: "Clínica"
}

export default CompanyTypes;

export const CompanyTypesOptions =
    Object.values(CompanyTypes).map(value => ({
        value: value, 
        label: CompanyTypesName[value],
    }));