<template>
    <nav aria-label="Page navigation example">
      <div 
        class="d-flex justify-content-center justify-content-sm-between flex-wrap" 
        style="padding: 24px 24px 0px"
      >
        <div>
          <p>Mostrando {{ from }} a {{ to }} de {{ totalValues }} entradas</p>
        </div>
        <ul class="pagination">
          <li :class="previousButtonClasses">
            <a @click="firstPage()" class="page-link" aria-label="Previous">
              <FontAwesomeIcon :icon="faAnglesLeft" />
            </a>
          </li>
          <li :class="previousButtonClasses">
            <a @click="previousPage()" class="page-link" aria-label="Previous">
              <span aria-hidden="true">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </span>
            </a>
          </li>
          <li :key="key" v-for="(page, key) in pages" :class="getItemClasses(page)">
            <a class="page-link" @click="onChangePage(page)">
              {{ page }}
            </a>
          </li>
          <li :class="nextButtonClasses">
            <a @click="nextPage()" class="page-link" aria-label="Next">
              <span aria-hidden="true">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </a>
          </li>
          <li :class="nextButtonClasses">
            <a @click="lastPage()" class="page-link" aria-label="Previous">
              <FontAwesomeIcon :icon="faAnglesRight" />
            </a>
          </li>
          <li :class="nextButtonClasses">
            <a on-click="perPage" class="" aria-label="">
              <soft-select
              id="on"
              name="type"
              v-model="perPageValue"
              :options="options"
              placeholder="Selecione o tipo"
              />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import SoftSelect from "@/components/SoftSelect";

  export default {
    name: "Pagination",
    props: {
      current: {
        type: Number,
        default: 1
      },
      totalPages: {
        type: Number,
        default: 1,
      },
      perPage: {
        type: Number,
        default: 1,
      },
      totalValues: {
        type: Number,
        default: 1,
      },
      maxPages: {
        type: Number,
        default: 5
      },
      onChangePage: {
        type: Function,
        default: () => { }
      },
      onChangePerPage: {
        type: Function,
        default: () => { }
      }
    },
    components: {
      FontAwesomeIcon,
      SoftSelect
    },
    data() {
      const options = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 30, value: 30}
      ]

      return {
        faAnglesLeft,
        faAnglesRight,
        options
      }
    },
    computed: {
      perPageValue:{
        get(){
          return this.perPage
        },
        set(newPerPage){
          this.$emit('update:perPage', newPerPage)
          this.onChangePerPage(newPerPage)
        }
      },
      pages() {
        const gap = Math.max(this.current - this.maxPages, 0);
        let start = 1 + gap;
        let end = Math.min(this.maxPages + gap, this.totalPages);
  
        if (end === this.current && this.current < this.totalPages) {
          start++;
          end++;
        }
  
        return Array.from({ length: end - start + 1 }, (_, i) => i + start);
      },
      from() {
        return this.perPage * (this.current - 1) + 1;
      },
      to() {
        return this.perPage * this.current <= this.totalValues
          ? this.perPage * this.current
          : this.totalValues;
      },
      previousButtonClasses() {
        const disabled = this.current === 1 ? 'disabled' : '';
        return `page-item ${disabled}`;
      },
      nextButtonClasses() {
        const disabled = this.current >= this.totalPages ? 'disabled' : '';
        return `page-item ${disabled}`;
      },
    },
    methods: {
      getItemClasses(page) {
        return `page-item ${page == this.current ? 'active' : ''}`;
      },
      previousPage() {
        this.onChangePage(this.current - 1);
      },
      nextPage() {
        this.onChangePage(this.current + 1);
      },
      firstPage() {
        this.onChangePage(1);
      },
      lastPage() {
        this.onChangePage(this.totalPages);
      },
    }
  }
  </script>
  