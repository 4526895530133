<template>
  <ErrorMessage
    :name="name"
    class="error-feedback text-xs text-danger"
    role="alert"
  />
  <div 
    :id="id"
    class="img-box"
    :class="{'bg-success': !previewImage}"
    @click="chooseImage()"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    @drop.prevent="onDrop"
    :style="{ 
      width: `${width}px`, 
      height: `${height}px`
    }"
  >
    <Field :name="name" v-model="value">
      <img v-if="previewImage" :src="previewImage" class="preview-image" />
      <div v-if="showMessage" class="msg" >
        <h5 class="text-white">Selecione uma imagem</h5>
      </div>
      <input hidden ref="imageUpload" type="file" accept="image/*" @change=onChange>
    </Field>
  </div>
</template>

<script>
import { ErrorMessage, Field } from 'vee-validate';


export default ({
  name: "SoftImageField",
  components: {
    Field,
    ErrorMessage
  },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewImage: null,
      showMessage: true
    }
  },
  watch: {
    initialValue(newInit) {
      this.previewImage = newInit;
      this.$refs.imageUpload.value = null;
      if (newInit) this.showMessage = false;
    },
    modelValue(newValue) {
      if (newValue) {
        this.previewImage = newValue;
        this.showMessage = false;
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value) 
      }
    },
  },
  methods: {
    chooseImage() {
      this.$refs.imageUpload.click();
    },
    onChange(e) {
      const image = e.target.files[0];
      if (image) {
        this.value = image;
  
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
      }
    },
    onDrop() {

    },
    onMouseOver() {
      this.showMessage = true;
    },
    onMouseLeave() {
      if (this.previewImage) {
        this.showMessage = false;
      }
    },
  }
})
</script>

<style>
  .img-box {
    display: flex;
    position: relative;
  }
  .preview-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .msg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    padding: 2em;

    text-align: center;

    background-color: rgba(0, 0, 0, .5);

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

  }
</style>