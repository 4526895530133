<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Empresas</h5>
              </div>
              <div class="ms-auto d-flex align-items-center mt-3 mt-lg-0">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  @input="searchCompanies"
                  :placeholder="'Buscar...'
                  "
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Nova Empresa
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="companies-list" ref="companiesList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByCorporateReason" class="click">
                      Razão Social
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': sortKey === 'corporate_reason' && sortOrder === 'asc',
                          'fa fa-chevron-down': sortKey === 'corporate_reason' && sortOrder === 'desc',
                          'fa fa-bars': sortKey !== 'corporate_reason'
                        }"></i>
                      </span>
                    </th>
                    <th>CNPJ</th>
                    <th>Tipo</th>
                    <th>Cidade</th>
                    <th>
                      Operadora de saúde
                      
                    </th>
                    <th>Ativo</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <company-tile v-for="(company, index) in filteredCompanies" :company="company" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
            :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <company-modal />
</template>

<script>
import CompanyTile from "./components/CompanyTile"
import CompanyModal from "./components/CompanyModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Companies",
  components: {
    CompanyTile,
    CompanyModal,
    Pagination
  },

  mixins: [list_mixin('companies')],

  data() {
    return {
      sortKey: '',
      sortOrder: '',
      searchQuery: '',
    };
  },

  computed: {
    ...mapState('companies', {
      companies: state => state.companies,
    }),
    filteredCompanies() {
      const query = this.searchQuery.toLowerCase();
      const filtered = this.companies.filter(company =>
        company.corporate_reason.toLowerCase().includes(query) 
      );

      let sorted = [...filtered];
      if (this.sortKey) {
        sorted.sort((a, b) => {
          if (this.sortKey === 'corporate_reason') {
            return this.sortOrder === 'asc'
              ? a.corporate_reason.localeCompare(b.corporate_reason)
              : b.corporate_reason.localeCompare(a.corporate_reason);
          } else if (this.sortKey === 'healthcare_operator') {
            return this.sortOrder === 'asc'
          }
        });
      }
      return sorted;
    }
  },

  async created() {
    try {
      await this.$store.dispatch("companies/getCompanies");
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async searchCompanies() {
      if (this.searchQuery.length > 2) {
        await this.$store.dispatch('companies/findCompanies', this.searchQuery);
      } else {
        await this.$store.dispatch('companies/getCompanies');
      }
    },
    startAdding() {
      this.$store.dispatch('companies/startAdding');
    },
    sortByCorporateReason() {
      if (this.sortKey === 'corporate_reason') {
        this.sortOrder = this.sortOrder === 'asc'
          ? 'desc'
          : this.sortOrder === 'desc'
          ? ''
          : 'asc';
        if (this.sortOrder === '') {
          this.sortKey = '';
        }
      } else {
        this.sortKey = 'corporate_reason';
        this.sortOrder = 'asc';
      }
    },
    sortByHealthcareOperator() {
      if (this.sortKey === 'healthcare_name') {
        this.sortOrder = this.sortOrder === 'asc'
          ? 'desc'
          : this.sortOrder === 'desc'
          ? ''
          : 'asc';
        if (this.sortOrder === '') {
          this.sortKey = '';
        }
      } else {
        this.sortKey = 'healthcare_name';
        this.sortOrder = 'asc';
      }
    },
    
  },
};
</script>

<style>
td {
  padding: 12px 24px !important;
}

.click {
  cursor: pointer;
}

.search-container {
  position: relative;
}

input[type="search"] {
  padding-left: 15px;
  padding-top: 10px;
}

input[type="search"]::placeholder {
  font-size: 20px;
  color: #6c757d; 
}

.search-container::before {
  content: "\f002"; 
  font-family: 'Courier New', Courier, monospace;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #6c757d;
}

</style>
