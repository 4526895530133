<template>
  <div 
    id="triage_form_modal"
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="triageForm"
            ref="triageForm"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <label for="personal_history">Histórico Pessoal</label>
            <soft-select-multiple 
              id="personal_history"
              name="personal_history"
              v-model="state.personal_history"
              :options="personalHistories" 
              placeholder="Histórico Pessoal"
            />

            <soft-switch-model 
              id="smoking"
              name="smoking"
              v-model="state.smoking"
            >
              Tabagismo
            </soft-switch-model>
            
            <soft-switch-model 
              id="wear_glasses"
              name="wear_glasses"
              v-model="state.wear_glasses"
            >
              Usa óculos
            </soft-switch-model>
            
            <soft-switch-model 
              id="wear_contact_lenses"
              name="wear_contact_lenses"
              v-model="state.wear_contact_lenses"
            >
              Usa lentes de contato
            </soft-switch-model>

            <label for="screen_time">Tempo de tela por dia</label>
            <soft-select 
              id="screen_time"
              name="screen_time"
              v-model="state.screen_time"
              :options="ScreenTimeOptions"
              placeholder="Tempo de tela por dia"
            />

            <label for="previous_eye_surgeries">Cirurgias oculares prévias</label>
            <soft-select-multiple 
              id="previous_eye_surgeries"
              name="previous_eye_surgeries"
              v-model="state.previous_eye_surgeries"
              :options="eyeSurgeries" 
              placeholder="Cirurgias oculares prévias"
            />

            <label for="previous_eye_diseases">Doenças oculares prévias</label>
            <soft-select-multiple 
              id="previous_eye_diseases"
              name="previous_eye_diseases"
              v-model="state.previous_eye_diseases"
              :options="eyeDiseases" 
              placeholder="Doenças oculares prévias"
            />
            
            <label for="eye_complaints">Queixas oftalmológicas</label>
            <soft-select-multiple 
              id="eye_complaints"
              name="eye_complaints"
              v-model="state.eye_complaints"
              :options="eyeComplaints" 
              placeholder="Queixas oftalmológicas"
            />
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="onClose"
          >Cancelar</button>
          <button
            type="submit"
            form="triageForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import { mapActions, mapState } from "vuex/dist/vuex.cjs.js";
import SoftSelectMultiple from "../../components/SoftSelectMultiple.vue";
import SoftSelect from "../../components/SoftSelect.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import { ScreenTimeOptions } from "../../constants/ScreenTime";
import { Form } from "vee-validate";
import * as yup from "yup";

function getState(triage = null) {
  return {
    id: triage?.id,
    personal_history: triage?.personal_history ?? [],
    smoking: triage?.smoking ?? false,
    wear_glasses: triage?.wear_glasses ?? false,
    wear_contact_lenses: triage?.wear_lenses ?? false,
    screen_time: triage?.screen_time ?? '',
    previous_eye_surgeries: triage?.previous_eye_surgeries ?? [],
    previous_eye_diseases: triage?.previous_eye_diseases ?? [],
    eye_complaints: triage?.eye_complaints ?? [],
  }
}

export default {
  name: "TriageFormModal",
  mixins: [formModalMixin('triages')],
  components: {
    SoftSelectMultiple,
    SoftSwitchModel,
    SoftSelect,
    Form
  },
  mounted() {
    this.triage_modal = new bootstrap.Modal("#triage_form_modal" , {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      personal_history: yup.array().of(yup.string()).label('Histórico Pessoal'),
      screen_time: yup.string().required().label('Tempo de tela por dia'),
      previous_eye_surgeries: yup.array().of(yup.string()).label('Cirurgias oculares prévias'),
      previous_eye_diseases: yup.array().of(yup.string()).label('Doenças oculares prévias'),
      eye_complaints: yup.array().min(1).required().of(yup.string()).label('Queixas oftalmológicas'),
    })

    return {
      schema,
      loading: false,
      state: getState(),
      ScreenTimeOptions
    }
  },
  computed: {
    ...mapState('triages', {
      open: state => state.form.open,
      triage: state => state.form.triage,
      patientId: state => state.form.patientId,
      title: state => state.form.triage?.id ? 'Editar Triagem' : 'Nova Triagem'
    }),
    personalHistories() {
      return this.$store.state.personalHistory.options
    },
    eyeSurgeries() {
      return this.$store.state.eyeSurgeries.options
    },
    eyeDiseases() {
      return this.$store.state.eyeDiseases.options
    },
    eyeComplaints() {
      return this.$store.state.eyeComplaints.options
    },
  },
  async created() {
    try {
      await this.$store.dispatch("personalHistory/getOptions");
      await this.$store.dispatch("eyeSurgeries/getOptions");
      await this.$store.dispatch("eyeDiseases/getOptions");
      await this.$store.dispatch("eyeComplaints/getOptions");
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openTriageModal();
      else this.closeTriageModal();
    },
  },
  methods: {
    ...mapActions('triages', {
      onClose: "stopEditing"
    }),
    async handleSubmit() {
      this.loading = true
      try {
        const data = {
          patientId: this.patientId,
          triage: this.state
        }

        if (this.triage) await this.$store.dispatch('triages/updateTriage', data);
        else await this.$store.dispatch('triages/addTriage', data);
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('triages/stopEditing');
    },
    openTriageModal() {
      this.triage_modal.show()
    },
    closeTriageModal() {
      this.triage_modal.hide()
    },
    resetForm() {
      this.state = getState(this.triage)
      this.$refs.triageForm.resetForm();
    },
  },
}
</script>
