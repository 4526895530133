<template>
    <tr @click="startEditing">
        <td>{{ id }}</td>
        <td>{{ created_at }}</td>
        <td>{{ updated_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['triage'],
    data: (instance) => ({
        id: instance.triage.id,
        created_at: moment(instance.triage.created_at).format("DD/MM/YYYY HH:mm"),
        updated_at: moment(instance.triage.updated_at).format("DD/MM/YYYY HH:mm"),
    }),
    methods: {
        startEditing() {
            const data = {
                patientId: this.triage.patient_id,
                triage: this.triage
            }
            this.$store.dispatch('triages/startEditing', data)
        },
    }
}
</script>