
const EquipamentsTypes ={
    COMPUTER: 1,
    REFRACTOR: 2, 
    RETINOGRAPHER: 3,
    TABLET: 4,
    PRINTER: 5,
    ROUTER: 6,
    CELL: 7,
}

export const EquipamentsTypesName = {
    1: "Notebook",
    2: "Refrator",
    3: "Retinógrafo",
    4: "Tablet",
    5: "Impressora",
    6: "Roteador",
    7: "Celular"

}

export default EquipamentsTypes;

export const EquipamentsTypesOptions = 
    Object.keys(EquipamentsTypes).map(key => {
        const value = EquipamentsTypes[key];
        return {
            value: String(value), 
            label: EquipamentsTypesName[value],
        };
    });