<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between align-items-center">
              <h5 class="mb-0">Usuários</h5>
              <div class="d-flex align-items-center mt-4 mt-lg-0">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  @input="handleSearch"
                  placeholder="Buscar..."
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                  +&nbsp; Novo Usuário
                </a>
              </div>
            </div>
          </div>

          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="users-list" ref="usersList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByName" class="click">
                      Nome&nbsp;
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': nameSortOrder === 1,
                          'fa fa-chevron-down': nameSortOrder === 2,
                          'fa fa-bars': nameSortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th @click="sortByEmail" class="click">
                      E-mail&nbsp;
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': emailSortOrder === 1,
                          'fa fa-chevron-down': emailSortOrder === 2,
                          'fa fa-bars': emailSortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>Ativo</th>
                    <th>Tipo de Usuário</th>
                    <th>Criado em</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <user-tile v-for="user in sortedUsers" :user="user" :key="user.id" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
            :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <user-modal />
</template>

<script>
import UserTile from "./components/UserTile"
import UserModal from "./components/UserModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Users",
  components: {
    UserTile,
    UserModal,
    Pagination
  },

  mixins: [list_mixin('users')],

  data() {
    return {
      searchQuery: '', 
      nameSortOrder: 0,
      emailSortOrder: 0,
    };
  },

  computed: {
    ...mapState('users', {
      users: state => state.users,
      loading: state => state.loading,
      page: state => state.page,
      perPage: state => state.perPage,
      lastPage: state => state.lastPage,
      total: state => state.total,
    }),

    sortedUsers() {
      let users = this.users;
      if (this.nameSortOrder === 1) {
        return [...users].sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.nameSortOrder === 2) {
        return [...users].sort((a, b) => b.name.localeCompare(a.name));
      } else if (this.emailSortOrder === 1) {
        return [...users].sort((a, b) => a.email.localeCompare(b.email));
      } else if (this.emailSortOrder === 2) {
        return [...users].sort((a, b) => b.email.localeCompare(a.email));
      } else {
        return users;
      }
    },
  },

  async created() {
    try {
      await this.$store.dispatch("users/getUsers");
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    startAdding() {
      this.$store.dispatch('users/startAdding');
    },
    sortByName() {
      this.nameSortOrder = (this.nameSortOrder + 1) % 3;
      this.emailSortOrder = 0;
    },
    sortByEmail() {
      this.emailSortOrder = (this.emailSortOrder + 1) % 3;
      this.nameSortOrder = 0;
    },
    handleSearch() {
      this.$store.dispatch("users/findUsers", this.searchQuery);
    },
  },
};
</script>

<style>
table tbody tr:nth-child(odd) {
  background-color: #ffff; 
}

table tbody tr:nth-child(even) {
  background-color: #5bccbf3b;
  border: 1px solid rgba(0, 0, 0, 0.034) 
}
</style>

<style scoped>
td {
  padding: 12px 24px !important;
}

.click {
  cursor: pointer;
}

.search-container {
  position: relative;
}

input[type="search"] {
  padding-left: 15px;
  padding-top: 10px;
}

input[type="search"]::placeholder {
  font-size: 20px;
  color: #6c757d; 
}

.search-container::before {
  content: "\f002"; 
  font-family: 'Courier New', Courier, monospace;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #6c757d;
}
</style>
