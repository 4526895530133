<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Pacientes</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0 d-flex align-items-center">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  @input="searchPatients"
                  :placeholder="'Buscar...'
                  "
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="my-auto ms-auto">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Novo Paciente
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="patients-list" ref="patientsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByName" class="click">
                      Nome
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': nameSortOrder === 1,
                          'fa fa-chevron-down': nameSortOrder === 2,
                          'fa fa-bars': nameSortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>CPF</th>
                    <th @click="sortByCompany" class="click">
                      Empresa
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': companySortOrder === 1,
                          'fa fa-chevron-down': companySortOrder === 2,
                          'fa fa-bars': companySortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>Ativo</th>
                    <th>Criado em</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <patient-tile
                    v-for="(patient, index) in sortedPatients"
                    :patient="patient"
                    :key="index"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <pagination
          :current ="page"
          :perPage="perPage"
          :onChangePage="changeToPage"
          :totalPages="lastPage"
          :totalValues="total"
          :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <patient-modal />
  <triage-modal />
  <exam-modal />
</template>

<script>
import PatientTile from "./components/PatientTile"
import PatientModal from "./components/PatientModal"
import { mapState } from 'vuex'
import TriageModal from "./components/TriageModal.vue";
import ExamModal from "./components/ExamModal.vue";
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Patients",
  components: {
    PatientTile,
    PatientModal,
    TriageModal,
    ExamModal,
    Pagination
  },

  mixins: [list_mixin('patients')],

  data() {
    return {
      searchQuery: "",
      nameSortOrder: 0,
      companySortOrder: 0,
    };
  },

  computed: {
    ...mapState('patients', {
      patients: state => state.patients,
      loading: state => state.loading
    }),

    filteredPatients() {
      return this.patients.filter(patient => 
        patient.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        patient.companyName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },

    sortedPatients() {
      const clonedPatients = [...this.filteredPatients];
      return clonedPatients.sort((a, b) => {
        const nameA = a.name ? a.name.toString() : "";
        const nameB = b.name ? b.name.toString() : "";
        const companyA = a.companyName ? a.companyName.toString() : "";
        const companyB = b.companyName ? b.companyName.toString() : "";

        if (this.nameSortOrder === 1) {
          return nameA.localeCompare(nameB);
        } else if (this.nameSortOrder === 2) {
          return nameB.localeCompare(nameA);
        } else if (this.companySortOrder === 1) {
          return companyA.localeCompare(companyB);
        } else if (this.companySortOrder === 2) {
          return companyB.localeCompare(companyA);
        } else {
          return 0;
        }
      });
    }
  },

  async created() {
    try {
      await this.$store.dispatch("patients/getPatients");
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    startAdding() {
      this.$store.dispatch('patients/startAdding');
    },

    async searchPatients() {
      await this.$store.dispatch('patients/findPatients', this.searchQuery);
    },
    sortByName() {
      this.nameSortOrder = (this.nameSortOrder + 1) % 3;
      this.companySortOrder = 0;
    },
    sortByCompany() {
      this.companySortOrder = (this.companySortOrder + 1) % 3;
      this.nameSortOrder = 0;
    },
  },
};
</script>


<style scoped>

input[type="search"]::placeholder {
  font-family: FontAwesome, sans-serif;
  font-size: 14px;
}

.search-container {
  position: relative;
}

input[type="search"] {
  padding-left: 15px;
  padding-top: 10px;
}

input[type="search"]::placeholder {
  font-size: 20px;
  color: #6c757d; 
}
</style>
