<template>
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary position-absolute end-0"
        aria-hidden="true"
        id="iconSidenav" 
        @click="toggleSidenav"
      ></i>
      <a class="m-0 navbar-brand" href="/">
        &nbsp;&nbsp;&nbsp;<img :src="logo" class="navbar-brand-img h-70 w-70" alt="main_logo" />
      </a>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo.png";
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    toggleSidenav() {
      console.log(this.$store.state)
      this.$store.state.showSidenav = !this.$store.state.showSidenav;
    },
  }
};
</script>
