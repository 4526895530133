const ScreenTime = {
    NONE: "Nenhuma",
    ONE_TO_THREE: "1-3 horas",
    THREE_TO_SIX: "3-6 horas",
    MORE_THAN_SIX: "> 6 horas",
}

export default ScreenTime;

export const ScreenTimeOptions =
    Object.values(ScreenTime).map(value => ({
        value: value, 
        label: value,
    }));
