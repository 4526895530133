<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ email }}</td>
        <td>{{ active }}</td>
        <td>{{ type }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';
import { UserTypesName } from '../../constants/UserTypes';

export default {
    props: ['user'],
    data: (instance) => ({
        name: instance.user.name,
        email: instance.user.email,
        active: instance.user.active ? "Sim" : "Não",
        type: UserTypesName[instance.user.type] ?? "Não definido",
        created_at: moment(instance.user.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('users/startEditing', this.user)
        }
    }
}
</script>