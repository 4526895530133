const UserTypes = {
    ADMINISTRATOR: 1,
    USER: 2,
    TECHNICIAN: 3,
}

export const UserTypesName = {
    1: "Administrador",
    2: "Secretário(a)",
    3: "Técnico"
}

export default UserTypes;

export const UserTypesOptions =
    Object.values(UserTypes).map(value => ({
        value: value, 
        label: UserTypesName[value],
    }));