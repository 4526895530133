<template>
  <div
    class="tab-pane fade"
    id="phone"
    role="tabpanel"
    aria-labelledby="phone-tab"
  >
    <div class="d-flex gap-3 flex-column">
      <div class="card border border-dark" v-for="(phone, key) in company?.phones" :key="phone.id">
        <div class="card-body">
          <div class="card-title">
            <a class="clickable" @click="removePhone(key)" data-effect="fadeOut">
              <i class="fa fa-times"></i>
            </a>
          </div>
          <label :for="`phones[${key}].contact_name`">Nome para contato</label>
          <soft-field
            :id="`phones[${key}].contact_name`"
            v-model="company.phones[key].contact_name"
            placeholder="Nome para contato"
            :name="`phones[${key}].contact_name`"
          />
          <label :for="`phones[${key}].department`">Departamento</label>
          <soft-select
            :id="`phones[${key}].department`"
            :name="`phones[${key}].department`"
            v-model="company.phones[key].department"
            :options="CompanyDepartmentOptions"
            placeholder="Selecione um departamento"
          />
          <label :for="`phones[${key}].phone`">Telefone</label>
          <soft-field
            :id="`phones[${key}].phone`"
            v-model="company.phones[key].phone"
            placeholder="Telefone"
            :name="`phones[${key}].phone`"
          />

          <label :for="`phones[${key}].extension`">Ramal</label>
          <soft-field
            :id="`phones[${key}].extension`"
            v-model="company.phones[key].extension"
            placeholder="Ramal"
            :name="`phones[${key}].extension`"
          />
        </div>
      </div>

      <button class="btn btn-success" type="button" @click="addPhone">
        + Adicionar
      </button>
    </div>
  </div>
</template>

<script>
  import SoftSelect from "../../components/SoftSelect";
  import { CompanyDepartmentOptions } from "../../constants/CompanyDepartments";
import SoftField from "../../components/SoftField.vue";

  export default {
    name: "CompanyPhonesTab",
    props: ["modelValue"],
    components: {
      SoftField,
      SoftSelect
    },
    data() {
      return { 
        CompanyDepartmentOptions
      };
    },
    computed: {
      company: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
        }
      }
    },
    methods: {
      addPhone() {
        this.company.phones.push({ 
          phone: "",
          contact_name: "",
          department: "",
          extension: "",
        })
      },
      removePhone(index) {
        this.company.phones.splice(index, 1);
      }
    }
  }
</script>