import api from "./axios";

export default {
    async getUsers(page = 1, per_page = 10) {
        const response = await api.get(`/users?page=${page}&per_page=${per_page}`);
        return response.data;
    },
    async addUser(user) {
        const response = await api.post('/users', user)

        return response.data;
    },
    async updateUser(user) {
        const response = await api.put(`/users/${user.id}`, user)

        return response.data;
    },

    async findUsers(query, page= 1, per_page = 10) {
        const response = await api.get(`/users`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;

    },
}