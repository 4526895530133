import { setLocale } from "yup";

setLocale({
    mixed: {
        required(params) {
            return `${params.label} é obrigatório`;
        },
        notType(params) {
            switch (params.type) {
                case 'number':
                    return `${params.label} deve conter um número`;
                case 'string':
                    return `${params.label} deve conter um texto`;
                default:
                    return 'Tipo incorreto';
            }
        }
    },
    string: {
        email() {
            return "Digite um email válido";
        },
        min(params) {
            return `${params.label} deve ter pelo menos ${params.min} caracteres`;
        },
        max(params) {
            return `${params.label} deve ter no máximo ${params.max} caracteres`;
        },
    },
    array: {
        min(params) {
            return `${params.label} deve ter pelo menos ${params.min} ${params.max > 1 ? 'items' : 'item'}`;
        },
        max(params) {
            return `${params.label} deve ter no máximo ${params.max} ${params.max > 1 ? 'items' : 'item'}`;
        },
    },
})