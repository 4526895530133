import api from "./axios";

export default {
    async getEquipaments(page = 1, per_page = 10) {
        const response = await api.get(`/equipaments?page=${page}&per_page=${per_page}`);
        return response.data;
    },
    async addEquipaments(equipaments) {
        const response = await api.post('/equipaments', equipaments)

        return response.data;
    },
    async updateEquipaments(equipaments) {
        const response = await api.put(`/equipaments/${equipaments.id}`, equipaments)

        return response.data;
    },
    async findEquipaments(query, page= 1, per_page = 10) {
        const response = await api.get(`/equipaments`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;
    },
}