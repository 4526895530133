<template>
    <tr @click="startEditing">
        <td>{{ type }}</td>
        <td>{{ mark }}</td>
        <td>{{ model }}</td>
        <td>{{ serial }}</td>
        <td>{{ active }}</td>
    </tr>
</template>

<script>
import { EquipamentsTypesName } from '../../constants/EquipamentsTypes';

export default {
    props: ['equipaments'],
    data: (instance) => ({
        type: EquipamentsTypesName[instance.equipaments.equipament_type] ?? "Não definido",
        mark: instance.equipaments.mark,
        model: instance.equipaments.model,
        serial: instance.equipaments.serial_number ,
        active: instance.equipaments.active_number,
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('equipaments/startEditing', this.equipaments)
        }
    }
}
</script>