<template>
  <div
    class="tab-pane fade"
    id="social_media"
    role="tabpanel"
    aria-labelledby="social_media-tab"
  >
    <div class="d-flex gap-3 flex-column">
      <div class="card border border-dark" v-for="(social_media, key) in company?.social_medias" :key="social_media.id">
        <div class="card-body">
          <div class="card-title">
            <a class="clickable" @click="removeSocialMedia(key)" data-effect="fadeOut">
              <i class="fa fa-times"></i>
            </a>
          </div>
          <label :for="`social_medias[${key}].name`">Nome</label>
          <soft-field
            :id="`social_medias[${key}].name`"
            v-model="company.social_medias[key].name"
            placeholder="Nome"
            :name="`social_medias[${key}].name`"
          />
          <label :for="`social_medias[${key}].social_media`">Mídia Social</label>
          <soft-field
            :id="`social_medias[${key}].social_media`"
            v-model="company.social_medias[key].social_media"
            placeholder="social_media"
            :name="`social_medias[${key}].social_media`"
          />
        </div>
      </div>

      <button class="btn btn-success" type="button" @click="addSocialMedia">
        + Adicionar
      </button>
    </div>
  </div>
</template>

<script>
import SoftField from "../../components/SoftField.vue";

  export default {
    name: "CompanySocialMediasTab",
    props: ["modelValue"],
    components: {
      SoftField,
    },
    computed: {
      company: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
        }
      }
    },
    methods: {
      addSocialMedia() {
        this.company.social_medias.push({ 
          social_media: "",
          contact_name: "",
          department: "",
        })
      },
      removeSocialMedia(index) {
        this.company.social_medias.splice(index, 1);
      }
    }
  }
</script>