<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Atendimentos</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                      +&nbsp; Novo Atendimento
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="spinner-border" role="status" v-if="loading">
              <span class="sr-only">Carregando...</span>
            </div>
            <div class="px-0 pb-0 card-body" v-else>
              <div class="table-responsive">
                <table id="equipaments-list" ref="equipamentsList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>Equipamentos</th>
                      <th>Técnico</th>
                      <th>Paciente</th>
                      <th>Atendido Em:</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                    <!-- <equipaments-tile v-for="(equipaments, index) in equipaments" :equipaments="equipaments" :key="index"/> -->
                  </tbody>
                </table>
              </div>
            </div>
            <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
            :onChangePerPage="changePerPage"
          />
          </div>
        </div>
      </div>
    </div>
  
    <supports-modal />
    
  </template>
  
  <script>
import { mapState } from 'vuex'
import SupportsModal from "./components/SupportsModal.vue";
import list_mixin from "../mixins/list_mixin";
import Pagination from "../components/Pagination.vue";

export default {
  name: "Supports",
  components: {
    SupportsModal,
    Pagination
  },

    mixins:[list_mixin('supports')],

  computed: {
    ...mapState('supports', {
      supports: state => state.supports
    }),
  },

};
</script>

<style>
td {
  padding: 12px 24px !important;
}

</style>