<template>
  <div class="modal fade" id="exam_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">Exames</h5>
        </div>
        <div class="modal-body">
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <table v-else id="exam-list" ref="ExamList" class="table table-flush">
          <thead class="thead-light">
            <tr>
              <th>Id</th>
              <th>Data de criação</th>
              <th>Ultima atualização</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <exam-tile 
              :exam="exam" 
              :key="index"
              v-for="( exam, index ) in exams"
            />
          </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClose">Fechar</button>
          <button type="button" class="btn btn-success" @click="startAdding(patientId)">
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>

  <exam-form-modal />
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import ExamTile from "./ExamTile.vue";
import { mapActions, mapState } from "vuex";
import ExamFormModal from "./ExamFormModal.vue";

export default {
  name: "ExamModal",
  components: {
    ExamTile,
    ExamFormModal
  },
  mounted() {
    this.exam_modal = new bootstrap.Modal(`#exam_modal`, {
      backdrop: 'static'
    });
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openExamModal();
      else this.closeExamModal();
    },
  },
  computed: {
    ...mapState('exams', {
      loading: state => state.loading,
      open: state => state.modal.open,
      patientId: state => state.modal.patientId,
      exams: state => state.modal.patientId
        ? state.exams[state.modal.patientId]
        : [],
    })
  },
  methods: {
    ...mapActions('exams', {
      onClose: "closeModal",
      startAdding: "startAdding",
    }),
    openExamModal() {
      this.exam_modal.show()
    },
    closeExamModal() {
      this.exam_modal.hide()
    },
  },
};
</script>