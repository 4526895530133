<template>
  <div
    class="tab-pane fade"
    id="email"
    role="tabpanel"
    aria-labelledby="email-tab"
  >
    <div class="d-flex gap-3 flex-column">
      <div class="card border border-dark" v-for="(email, key) in company?.emails" :key="email.id">
        <div class="card-body">
          <div class="card-title">
            <a class="clickable" @click="removeEmail(key)" data-effect="fadeOut">
              <i class="fa fa-times"></i>
            </a>
          </div>
          <label :for="`emails[${key}].contact_name`">Nome para contato</label>
          <soft-field
            :id="`emails[${key}].contact_name`"
            v-model="company.emails[key].contact_name"
            placeholder="Nome para contato"
            :name="`emails[${key}].contact_name`"
          />
          <label :for="`emails[${key}].department`">Departamento</label>
          <soft-select
            :id="`emails[${key}].department`"
            :name="`emails[${key}].department`"
            v-model="company.emails[key].department"
            :options="CompanyDepartmentOptions"
            placeholder="Selecione um departamento"
          />
          <label :for="`emails[${key}].email`">Email</label>
          <soft-field
            :id="`emails[${key}].email`"
            v-model="company.emails[key].email"
            placeholder="Email"
            type="email"
            :name="`emails[${key}].email`"
          />
        </div>
      </div>

      <button class="btn btn-success" type="button" @click="addEmail">
        + Adicionar
      </button>
    </div>
  </div>
</template>

<script>
  import SoftSelect from "../../components/SoftSelect";
  import { CompanyDepartmentOptions } from "../../constants/CompanyDepartments";
import SoftField from "../../components/SoftField.vue";

  export default {
    name: "CompanyEmailsTab",
    props: ["modelValue"],
    components: {
      SoftField,
      SoftSelect
    },
    data() {
      return { 
        CompanyDepartmentOptions
      };
    },
    computed: {
      company: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
        }
      }
    },
    methods: {
      addEmail() {
        this.company.emails.push({ 
          email: "",
          contact_name: "",
          department: "",
        })
      },
      removeEmail(index) {
        this.company.emails.splice(index, 1);
      }
    }
  }
</script>