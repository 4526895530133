<template>
  <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
    <div class="d-flex gap-3 flex-column">
      <div class="card border border-dark" v-for="(history, key) in equipaments?.history" :key="key">
        <div class="card-body">
          <div class="card-title">
            <a class="clickable" @click="removeHistory(key)" data-effect="fadeOut">
              <i class="fa fa-times"></i>
            </a>
          </div>

          <label :for="`user_${key}`">Atribuir a</label>
          <soft-select-search
          id="users_id"
          name="users_id"
          v-model="equipaments.users"
          :options="users"
          placeholder="Selecione o Usuário:"
          />
         
          
      <label for="return_date">Data de Recebimento</label>
      <soft-date-time-picker
        id="return_date"
        v-model="history.return_date"
        placeholder="Selecione a Data"
        name="return_date"
        format="dd/MM/yyyy"
        model-type="yyyy-MM-dd"
        :enable-time-picker="false"
      />
          
          <label for="image">Upload do Termo De Recebimento</label>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  @change="handleImageUpload"
                  ref="equipamentImageUpload"
                />
                <img
                  v-if="history.imagePreview"
                  :src="history.imagePreview"
                  class="preview-image mt-2"
                  alt="Prévia da Imagem"
                  style="max-width: 100%; height: auto;"
                />
              </div>

          <label for="withdrawal_date">Data de Devolução</label>
      <soft-date-time-picker
        id="withdrawal_date"
        v-model="history.withdrawal_date"
        placeholder="Selecione a Data"
        name="withdrawal_date"
        format="dd/MM/yyyy"
        model-type="yyyy-MM-dd"
        :enable-time-picker="false"
      />

      
  
              <label for="insurance">Upload do Termo de Devolução</label>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  @change="handleInsuranceUpload"
                  ref="insuranceImageUpload"
                />
                <img
                  v-if="history.insuranceImagePreview"
                  :src="history.insuranceImagePreview"
                  class="preview-image mt-2"
                  alt="Prévia do Seguro"
                  style="max-width: 100%; height: auto;"
                />
              </div>
              <label for="obs_history">Observações</label>
              <soft-field
                class="custom-label"
                id="obs_history"
                v-model="history.obs"
                type="text"
                placeholder="Observações Gerais"
                name="obs"
              />

            </div>
      </div>

      <button class="btn btn-success" type="button" @click="addHistory">
        + Adicionar
      </button>
    </div>
  </div>
</template>

<script>
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import SoftDateTimePicker from "../../components/SoftDateTimePicker.vue";

import SoftField from "../../components/SoftField.vue";


export default {
  name: "EquipamentsHistoryTab",
  props: ["modelValue"],
  components: {
    SoftDateTimePicker,
    SoftSelectSearch,
    SoftField
  },
 
  computed: {
    equipaments: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    users() {
      return this.$store.getters['users/options'];
    }
  },
  async created() {
    try {
     
        await this.$store.dispatch("users/getUsers");
     
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    addHistory() {
      this.equipaments.history = this.equipaments.history || [];
      this.equipaments.history.push({
        user: null,
        withdrawal_date: null,
        trd: null,
        return_date: null,
        tdd: null,
        imagePreview:null,
        insuranceImagePreview:null,
      });
    },
    removeHistory(index) {
      this.equipaments.history.splice(index, 1);
    },
    validateReturnDate(date, key) {
      const currentDate = new Date().toISOString().split('T')[0];
      if (date > currentDate) {
        alert('A data de devolução não pode ser posterior à data atual.');
        this.equipaments.history[key].return_date = currentDate; 
      }
    },
    handleFileUpload(event, key, type) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.equipaments.history[key][type] = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>
