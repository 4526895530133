<template>
    <div
      class="tab-pane fade"
      id="phones"
      role="tabpanel"
      aria-labelledby="phones-tab"
    >
      <div class="d-flex gap-3 flex-column">
        <div class="card border border-dark" v-for="(phones, key) in patient?.phones" :key="phones.id">
          <div class="card-body">
            <div class="card-title">
              <a class="clickable" @click="removePhones(key)" data-effect="fadeOut">
                <i class="fa fa-times"></i>
              </a>
            </div>
            <label :for="`name_${key}`">Nome p/ contato</label>
            <soft-model-input
              :id="`name_${key}`"
              v-model="patient.phones[key].name"
              placeholder="Nome p/contato"
              :name="`name_${key}`"
            />
            <label :for="`phone_${key}`">Telefone</label>
            <soft-model-input
              :id="`phone_${key}`"
              v-model="patient.phones[key].phone"
              placeholder="Telefone"
              :name="`phone_${key}`"
            />
          </div>
        </div>
  
        <button class="btn btn-success" type="button" @click="addPhones">
          + Adicionar
        </button>
      </div>
    </div>
  </template>
  
  <script>
    import SoftModelInput from "@/components/SoftModelInput.vue";
  
    export default {
      name: "PatientPhonesTab",
      props: ["modelValue"],
      components: {
        SoftModelInput,
      },
      computed: {
        patient: {
          get() {
            return this.modelValue
          },
          set(newValue) {
            this.$emit('update:modelValue', newValue)
          }
        }
      },
      methods: {
        addPhones() {
          this.patient.phones.push({ 
            name: "",
            phone: "",
          })
        },
        removePhones(index) {
          this.patient.phones.splice(index, 1);
        }
      }
    }
  </script>