<template>
  <div class="modal fade" id="triage_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">Triagem</h5>
        </div>
        <div class="modal-body">
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <table v-else id="triage-list" ref="TriageList" class="table table-flush">
          <thead class="thead-light">
            <tr>
              <th>Id</th>
              <th>Data de criação</th>
              <th>Ultima atualização</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <triage-tile 
              :triage="triage" 
              :key="index"
              v-for="( triage, index ) in triages"
            />
          </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClose">Fechar</button>
          <button type="button" class="btn btn-success" @click="startAdding(patientId)">
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>

  <triage-form-modal />
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import TriageTile from "./TriageTile.vue";
import { mapActions, mapState } from "vuex";
import TriageFormModal from "./TriageFormModal.vue";

export default {
  name: "TriageModal",
  components: {
    TriageTile,
    TriageFormModal
  },
  mounted() {
    this.triage_modal = new bootstrap.Modal(`#triage_modal`, {
      backdrop: 'static'
    });
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openTriageModal();
      else this.closeTriageModal();
    },
  },
  computed: {
    ...mapState('triages', {
      loading: state => state.loading,
      open: state => state.modal.open,
      patientId: state => state.modal.patientId,
      triages: state => state.modal.patientId
        ? state.triages[state.modal.patientId]
        : [],
    })
  },
  methods: {
    ...mapActions('triages', {
      onClose: "closeModal",
      startAdding: "startAdding",
    }),
    openTriageModal() {
      this.triage_modal.show()
    },
    closeTriageModal() {
      this.triage_modal.hide()
    },
  },
};
</script>