<template>
  <div 
    id="exam_form_modal"
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="examForm"
            ref="examForm"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <h4>Acuidade Visual</h4>

            <label for="name">Olho direito</label>
            <soft-select-search
              id="right_eye"
              name="right_eye"
              v-model="state.right_eye"
              :options="VisualAcuityOptions"
              placeholder="Olho direito"
            />
            
            <label for="name">Olho esquerdo</label>
            <soft-select-search
              id="left_eye"
              name="left_eye"
              v-model="state.left_eye"
              :options="VisualAcuityOptions"
              placeholder="Olho esquerdo"
            />

            <div class="d-flex flex-column align-items-center gap-5 mt-5">
              <div class="d-flex flex-column align-items-center gap-2">
                <h4>Auto Refrator Portátil</h4>
                <soft-image-field 
                  id="portable_auto_refractor_img"
                  name="portable_auto_refractor_img"
                  :initialValue="state.portable_auto_refractor"
                  v-model="state.portable_auto_refractor_img"
                />
              </div>
              
              <div class="d-flex flex-column align-items-center gap-2">
                <h4>Retinografia</h4>
                <soft-image-field 
                  id="retinography_img"
                  name="retinography_img"
                  :initialValue="state.retinography"
                  v-model="state.retinography_img"
                />
              </div>
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="onClose"
          >Cancelar</button>
          <button
            type="submit"
            form="examForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import { mapActions, mapState } from "vuex/dist/vuex.cjs.js";
import formModalMixin from "../../mixins/form-modal-mixin";
import SoftImageField from "@/components/SoftImageField.vue";
import { VisualAcuityOptions } from "../../constants/VisualAcuity";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import * as yup from 'yup'
import { Form } from "vee-validate";

function getState(exam = null) {
  return {
    id: exam?.id,
    right_eye: exam?.right_eye ?? '',
    left_eye: exam?.left_eye ?? '',
    portable_auto_refractor: exam?.portable_auto_refractor_img ?? '',
    retinography: exam?.retinography_img ?? '',
    portable_auto_refractor_img: null,
    retinography_img: null,
  }
}

export default {
  name: "ExamFormModal",
  mixins: [formModalMixin('exams')],
  components: {
    SoftImageField,
    SoftSelectSearch,
    Form
  },
  mounted() {
    this.exam_modal = new bootstrap.Modal("#exam_form_modal" , {
      backdrop: 'static'
    });
  },
  data() {
    return {
      loading: false,
      state: getState(),
      VisualAcuityOptions
    }
  },
  computed: {
    ...mapState('exams', {
      open: state => state.form.open,
      exam: state => state.form.exam,
      patientId: state => state.form.patientId,
      title: state => state.form.exam?.id ? 'Editar Exame' : 'Nova Exame'
    }),

    schema() {
      return yup.object().shape({
        right_eye: yup.string().required().label("Olho direito"),
        left_eye: yup.string().required().label("Olho esquerdo"),
        portable_auto_refractor_img: yup.mixed().label('Auto Refrator Portátil')
          .when((_, schema) => this.exam ? schema.notRequired() : schema.required()),
        retinography_img: yup.mixed().label('Retinografia')
          .when((_, schema) => this.exam ? schema.notRequired() : schema.required()),
      });
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openExamModal();
      else this.closeExamModal();
    },
  },
  methods: {
    ...mapActions('exams', {
      onClose: "stopEditing"
    }),
    async handleSubmit() {
      this.loading = true
      try {
        const data = {
          patientId: this.patientId,
          exam: this.state
        }

        if (this.exam) await this.$store.dispatch('exams/updateExam', data);
        else await this.$store.dispatch('exams/addExam', data);
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('exams/stopEditing');
    },
    openExamModal() {
      this.exam_modal.show()
    },
    closeExamModal() {
      this.exam_modal.hide()
    },
    resetForm() {
      this.state = getState(this.exam)
      this.$refs.examForm.resetForm();
    },
  },
}
</script>
