import healthcareOperatorsService from "../services/healthcareOperators.service"
import { parseFormError } from "../services/utils";

const initialState = {
  healthcareOperators: [],
  options:[],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total: 0,
  query: "",
  form: {
    open: false,
    healthcareOperator: null,
    error: null
  }
}

export const healthcareOperators = {
  namespaced: true,
  state: initialState,
  actions: {
    async getHealthcareOperators({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await healthcareOperatorsService.getHealthcareOperators(state.page, state.perPage);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page)
    },
    async findOperators({ commit, state }, query) {
      commit("SET_QUERY", query); 
      commit("SET_LOADING", true);
      const response = await healthcareOperatorsService.findOperators(query, state.page, state.perPage);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({commit, dispatch, state}, page){
      if (page >=1 && page <= state.lastPage){
        commit("SET_PAGE", page);
        dispatch("getHealthcareOperators")
      }
    },
    changePerPage({commit, dispatch}, perPage){
      commit("SET_PER_PAGE", perPage);
      dispatch("getHealthcareOperators")
    },
    startAdding({ commit }) {
      const value = { open: true, healthcareOperator: null };
      commit("SET_FORM", value);
    },
    async getOptions({commit}){
      commit("SET_LOADING", true);
      const response = await healthcareOperatorsService.getOptions();
      commit("SET_OPTIONS", response);
    },
    async addHealthcareOperator({ commit, dispatch }, healthcareOperator) {
      try {
        await healthcareOperatorsService.addHealthcareOperator(healthcareOperator);
        dispatch("stopEditing");
        dispatch("getHealthcareOperators");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, healthcareOperator) {
      const value = { open: true, healthcareOperator: healthcareOperator };
      commit("SET_FORM", value);
    },
    async updateHealthcareOperator({ commit, dispatch }, healthcareOperator) {
      try {
        await healthcareOperatorsService.updateHealthcareOperator(healthcareOperator);
        dispatch("stopEditing");
        dispatch("getHealthcareOperators");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, healthcareOperator: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_USERS(state, healthcareOperators) {
      state.healthcareOperators = healthcareOperators
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      console.log(total)
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_OPTIONS(state, companies){
      state.options = companies
      state.loading = false
    },

    SET_FORM(state, { open, healthcareOperator }) {
      state.form.open = open;
      state.form.healthcareOperator = healthcareOperator;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.healthcareOperators = [];
      state.loading = false;
      state.form = {
        open: false,
        healthcareOperator: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.healthcareOperators.length;
    },
    options(state) {
      return state.healthcareOperators.map(insurance => ({
        label: insurance.name,
        value: insurance.id
      }))
    }
  }
}
