<template>
  <div class="form-group mb-0">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <Field
        :id="id"
        :name="name"
        :isRequired="isRequired"
        v-model="validateValue"
      >
        <multiselect
          v-model="value"
          :class="getClasses(size, success, error)"
          :placeholder="placeholder"
          trackBy="value"
          label="label"
          :options="options"
          selectLabel="Aperte enter para selecionar"
          noResult="Nenhum elemento encontrado"
        />
      </Field>
      <ErrorMessage
        :name="name"
        class="error-feedback text-xs text-danger"
        role="alert"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { ErrorMessage, Field } from 'vee-validate';
import Multiselect from 'vue-multiselect';

export default {
  name: "SoftSelectSearch",
  components: { 
    Multiselect,
    Field,
    ErrorMessage
  },
  props: {
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Selecione",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      innerValue: ""
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue) {
          this.innerValue = this.options.find(
            option => option.value == newValue
          );
        } else {
          this.innerValue = ""
        }
      },
      immediate: true
    }
  },
  computed: {
    value: {
      get() {
        return this.innerValue
      },
      set(value) {
        if (value) {
          this.innerValue = value
          this.$emit('update:modelValue', value.value) 
        }
      }
    },
    validateValue() {
      return this.innerValue?.value ?? ''
    }
  },
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
  },
};
</script>