import api from "./axios";

export default {
    async getCompanies(page = 1, per_page = 10) {
        const response = await api.get(`/companies?page=${page}&per_page=${per_page}`);
        return response.data;
    },

    async getOptions(companytype = null){
        const response = companytype
            ? await api.get(`/company/options?companytype=${companytype}`)
            : await api.get(`/company/options`)
        console.log("response:",response)
        return response.data;
    },
    async addCompany(company) {
        const response = await api.post("/companies", company)

        return response.data;
    },
    async updateCompany(company) {
        const response = await api.put(`/companies/${company.id}`, company)

        return response.data;
    },

    async findCompanies(query, page= 1, per_page = 10) {
        const response = await api.get(`/companies`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        })
        return response.data;
    }
}