<template>
  <div class="modal fade" id="user_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <h4>Acesso</h4>
            <label for="name">Nome:</label>
            <soft-field
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
              :rules="{ required: true }"
            />
            <label for="email">E-mail:</label>
            <soft-field
              id="email"
              v-model="state.email"
              type="email"
              placeholder="E-mail"
              name="email"
              :rules="{ required: true, email: true }"
            />
            <label>Senha:</label>
            <soft-field
              id="password"
              v-model="state.password"
              type="password"
              placeholder="Senha"
              name="password"
              :rules="!this.user ? { required: true } : {}"
            />
            <label>Tipo de Usuário:</label>
            <soft-select-search
              id="userType"
              name="userType"
              v-model="state.type"
              :options="UserTypesOptions"
              placeholder="Selecione o tipo de usuário"
              :rules="{ required: true }"
            />
            <label>Ativo</label>
            <soft-switch-model
              id="active"
              name="active"
              v-model="state.active"
              :class="{ 'inactive-border': !state.active}"
            >
            </soft-switch-model>
            <br>
            <h4>Dados pessoais</h4>
            <label>CPF</label>
            <soft-field
              id="cpf"
              v-model="state.cpf"
              type="text"
              placeholder="CPF"
              name="cpf"
              :rules="{ required: true }"
            />
            <label>RG</label>
            <soft-field
              id="rg"
              v-model="state.rg"
              type="text"
              placeholder="RG"
              name="rg"
              :rules="{ required: true }"
            />
            <label for="dob">Data de Nascimento</label>
            <soft-date-time-picker
              id="dob"
              v-model="state.dob"
              placeholder="Data de Nascimento"
              name="dob"
              format="dd/MM/yyyy"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              :rules="{ required: true }"
            />
            <label for="sex">Gênero</label>
              <soft-select
                id="sex"
                name="sex"
                v-model="state.sex"
                :options="[
                {value: 'M', label: 'Masculino'},
                {value: 'F', label: 'Feminino'},
                {value: 'P', label: 'Prefiro não dizer'},
                ]"
                placeholder="Selecione o gênero:"
                :rules="{ required: true }"
              />
            <label>Telefone</label>
            <soft-field
              id="phone"
              v-model="state.phone"
              type="text"
              placeholder="Telefone"
              name="phone"
              :rules="{}" 
            />
            <br>
            <h4>Endereço</h4>
            <label for="zip_code">CEP (Somente Números)</label>
            <soft-field
              id="zip_code"
              v-model="state.address_zip_code"
              placeholder="CEP"
              name="zip_code"
              @input="handleCEPChange"
              debounce="500"
              :rules="{ required: true }"
              :class="{ 'is-invalid': !state.address_zip_code && error }"
            />
            <small v-if="!state.address_zip_code && error" class="text-danger">O campo CEP é obrigatório.</small>
            <label for="address_street">Rua</label>
            <soft-field
              id="address_street"
              v-model="state.address_street"
              placeholder="Rua"
              name="address_street"
              :rules="{ required: true }"
            />
            <label for="address_number">Número</label>
            <soft-field
              id="address_number"
              v-model="state.address_number"
              placeholder="Número"
              name="address_number"
              :rules="{ required: true }"
            />
            <label for="address_neighborhood">Bairro</label>
            <soft-field
              id="address_neighborhood"
              v-model="state.address_neighborhood"
              placeholder="Bairro"
              name="address_neighborhood"
              :rules="{ required: true }"
            />
            <label for="address_complement">Complemento</label>
            <soft-field
              id="address_complement"
              v-model="state.address_complement"
              placeholder="Complemento"
              name="address_complement"
              :rules="{}"
            />
            <label for="address_city">Cidade</label>
            <soft-field
              id="address_city"
              v-model="state.address_city"
              placeholder="Cidade"
              name="address_city"
              :rules="{ required: true }"
            />
            <label for="address_state">Estado</label>
            <soft-field
              id="address_state"
              v-model="state.address_state"
              placeholder="Estado"
              name="address_state"
              :rules="{ required: true }"
            />
            <label for="address_country">País</label>
            <soft-field
              id="address_country"
              v-model="state.address_country"
              placeholder="País"
              name="address_country"
              :rules="{ required: true }"
            />
          </Form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import "vue-search-select/dist/VueSearchSelect.css"
import * as yup from "yup";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";
import showSwal from "../../mixins/showSwal";
import { Form } from "vee-validate";
import SoftField from "../../components/SoftField.vue";
import { UserTypesOptions } from "../../constants/UserTypes";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import SoftDateTimePicker from "../../components/SoftDateTimePicker.vue";
import SoftSelect from "../../components/SoftSelect.vue";
const getState = (user) => ({
  id: user?.id,
  name: user?.name ?? '',
  email: user?.email ?? '',
  password: '',
  active: user?.active ?? true,
  is_admin: user?.is_admin ?? false,
  type: user?.type ?? '',
  cpf: user?.cpf ?? '',
  rg: user?.rg ?? '', 
  dob: user?.dob ?? '',
  sex: user?.sex ?? '',
  phone: user?.phone ?? '',
  address_zip_code: user?.address_zip_code ?? '',
  address_street: user?.address_street ?? '',
  address_number: user?.address_number ?? '',
  address_neighborhood: user?.address_neighborhood ?? '',
  address_complement: user?.address_complement ?? '',
  address_city: user?.address_city ?? '',
  address_state: user?.address_state ?? '',
  address_country: user?.address_country ?? ''
})
export default {
  name: "UserModal",
  mixins: [formModalMixin('users')],
  components: {
    Form,
    SoftField,
    SoftSwitchModel,
    SoftSelectSearch,
    SoftDateTimePicker,
    SoftSelect,
  },
  mounted() {
    this.user_modal = new bootstrap.Modal('#user_modal', {
      backdrop: 'static'
    });
  },
  data() {
    return {
      UserTypesOptions,
      loading: false,
      state: getState(null)
    }
  },
  computed: {
    ...mapState('users', {
      open: state => state.form.open,
      user: state => state.form.user,
      title: state => state.form.user?.name ?? "Novo Usuário",
    }),
    schema() {
      return yup.object().shape({
        name: yup.string().required().label('Nome'),
        email: yup.string().email().required().label('Email'),
        password: yup.string().label('Senha').when((_, schema) =>
          this.user ? schema.notRequired() : schema.required()
        ),
        cpf: yup.string().required().label('CPF'), 
        rg: yup.string().required().label('RG'),
        sex: yup.string().oneOf(['M', 'F', 'P']).required().label()
      });
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openUserModal();
      else this.closeUserModal();
    },
  },
  methods: {
    async handleSubmit() {
      
      if (!this.state.address_zip_code) {
      alert('O campo CEP é obrigatório.');
      return;
    }

      this.loading = true
      try {
        if (this.user)
        await this.$store.dispatch('users/updateUser', this.state);
        else
        await this.$store.dispatch('users/addUser', this.state);
        if (this.error == null){
          showSwal.methods.showSwal({
              type:"success",
              message: "Usuário cadastrado com sucesso!",
              timer: 5000
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('users/stopEditing');
    },
    openUserModal() {
      this.user_modal.show()
    },
    closeUserModal() {
      this.user_modal.hide()
    },
    resetForm() {
      this.state = getState(this.user)
      this.$refs.form.resetForm();
    },
    async handleCEPChange() {
      const cep = this.state.address_zip_code.replace(/\D/g, '');

      if (cep.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          const data = await response.json();

          if (!data.erro) {
            this.state.address_street = data.logradouro;
            this.state.address_neighborhood = data.bairro;
            this.state.address_city = data.localidade;
            this.state.address_state = data.uf;
            this.state.address_country = 'Brasil';
          } else {
            alert("CEP não encontrado!");
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
        }
      }
    }
  },
}
</script>