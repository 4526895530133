import api from "./axios";

export default {
  async getSupports() {
    const response = await api.get('/supports-calls'); // Alterado para 'supports-calls'
    return response.data;
  },
  async addSupports(supports) {
    const response = await api.post('/supports-calls', supports); // Alterado para 'supports-calls'
    return response.data;
  },
  async updateSupport(supports) {
    const response = await api.put(`/supports-calls/${supports.id}`, supports); // Alterado para 'supports-calls'
    return response.data;
  },
};
