<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between align-items-center">
              <h5 class="mb-0">Operadores de Saúde</h5>
              <div class="d-flex align-items-center mt-4 mt-lg-0">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  @input="searchOperators" 
                  placeholder="Buscar..."
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                  +&nbsp; Nova Operadora
                </a>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <!-- Mantendo apenas uma tabela -->
            <div class="table-responsive">
              <table id="healthcareOperators-list" ref="healthcareOperatorsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByName" class="click">
                      Nome&nbsp;
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': sortOrder === 1,
                          'fa fa-chevron-down': sortOrder === 2,
                          'fa fa-bars': sortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>Ativo</th>
                    <th>Criado em</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <healthcareOperator-tile 
                    v-for="(healthcareOperator, index) in filteredHealthcareOperators" 
                    :healthcareOperator="healthcareOperator" 
                    :key="index" 
                  />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
            :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <healthcareOperator-modal />
</template>

<script>
import HealthcareOperatorTile from "./components/HealthcareOperatorTile"
import HealthcareOperatorModal from "./components/HealthcareOperatorModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "HealthcareOperators",
  components: {
    HealthcareOperatorTile,
    HealthcareOperatorModal,
    Pagination
  },

  mixins: [list_mixin('healthcareOperators')],

  data() {
    return {
      searchQuery: '',
      sortOrder: 0
    };
  },

  computed: {
    ...mapState('healthcareOperators', {
      healthcareOperators: state => state.healthcareOperators,
      loading: state => state.loading,
      page: state => state.page,
      perPage: state => state.perPage,
      lastPage: state => state.lastPage,
      total: state => state.total
    }),
    filteredHealthcareOperators() {
      const searchQueryLower = this.searchQuery ? this.searchQuery.toLowerCase() : '';

      const filtered = this.healthcareOperators.filter(operator => {
        const name = operator.name ? operator.name.toLowerCase() : '';
        return name.includes(searchQueryLower);
      });

      if (this.sortOrder === 1) {
        return filtered.sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.sortOrder === 2) {
        return filtered.sort((a, b) => b.name.localeCompare(a.name));
      } else {
        return filtered;
      }
    }
  },
  
  async created() {
    try {
      await this.$store.dispatch("healthcareOperators/getHealthcareOperators");
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    startAdding() {
      this.$store.dispatch('healthcareOperators/startAdding');
    },
    sortByName() {
      this.sortOrder = (this.sortOrder + 1) % 3;
    },
    async searchOperators() {
      this.$store.commit('healthcareOperators/SET_PAGE', 1);
      await this.$store.dispatch('healthcareOperators/findOperators', this.searchQuery);
    }
  }
};
</script>

<style>
td {
  padding: 12px 24px !important;
}

.click {
  cursor: pointer;
}

input[type="search"]::placeholder {
  font-family: FontAwesome, sans-serif;
  font-size: 14px;
}
</style>
