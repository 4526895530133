<template>
  <div class="modal fade" id="patient_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="data-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#data"
                  role="tab"
                  aria-controls="data"
                  aria-selected="true"
                >Dados</a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="phones-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#phones"
                  role="tab"
                  aria-controls="phones"
                  aria-selected="false"
                >Telefones</a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="social_media-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#social_media"
                  role="tab"
                  aria-controls="social_media"
                  aria-selected="false"
                >Mídias Sociais</a>
              </li>
            </ul>

            <div class="tab-content" id="ex1-content">
              <patient-data-tab v-model="state" />
              <patient-phones-tab v-model="state" />
              <patient-social-medias-tab v-model="state" />
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="stopEditing"
          >Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import showSwal from "../../mixins/showSwal";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";
import PatientDataTab from "./PatientDataTab.vue";
import PatientPhonesTab from "./PatientPhonesTab.vue";
import PatientSocialMediasTab from "./PatientSocialMediasTab.vue";
import * as yup from 'yup';
import { Form } from "vee-validate";
import moment from "moment";
import HealthTypes from "../../constants/HealthTypes";

const getState = (patient) => ({
  id: patient?.id,
  company: patient?.company ?? '',
  name: patient?.name ?? '',
  email: patient?.email ?? '',
  dob: patient?.dob ?? '',
  zip_code: patient?.zip_code ?? '',
  address_street: patient?.address_street ?? '',
  address_number: patient?.address_number ?? '',
  address_neighborhood: patient?.address_neighborhood ?? '',
  address_complement: patient?.address_complement ?? '',
  address_city: patient?.address_city ?? '',
  address_state: patient?.address_state ?? '',
  address_country: patient?.address_country ?? '',
  health_card_number: patient?.health_card_number?? '',
  rg: patient?.rg ?? '',
  cpf: patient?.cpf ?? '',
  sex: patient?.sex ?? '',
  occupation: patient?.occupation ?? '',
  active: patient?.active ?? true,
  clinic: patient?.clinic ?? '',

  responsible_name: patient?.responsible_name ?? '',
  responsible_email: patient?.responsible_email ?? '',
  responsible_dob: patient?.responsible_dob ?? '',
  responsible_zip_code: patient?.responsible_zip_code ?? '',
  responsible_address_street: patient?.responsible_address_street ?? '',
  responsible_address_number: patient?.responsible_address_number ?? '',
  responsible_address_neighborhood: patient?.responsible_address_neighborhood ?? '',
  responsible_address_complement: patient?.responsible_address_complement ?? '',
  responsible_address_city: patient?.responsible_address_city ?? '',
  responsible_address_state: patient?.responsible_address_state ?? '',
  responsible_address_country: patient?.responsible_address_country ?? '',
  responsible_rg: patient?.responsible_rg ?? '',
  responsible_cpf: patient?.responsible_cpf ?? '',
  responsible_sex: patient?.responsible_sex ?? '',
  responsible_occupation: patient?.responsible_occupation ?? '',

  health_type: patient?.health_type ?? '',
  healthcare_operator_id: patient?.healthcare_operator_id ?? '',
  sus_card: patient?.sus_card ?? '',
  health_insurance: patient?.health_insurance ?? '',

  phones: patient?.phones ?? [],
  social_medias: patient?.social_medias ?? [],
})

export default {
  name: "PatientModal",
  mixins: [formModalMixin('patients')],
  components: {
    Form,
    PatientDataTab,
    PatientPhonesTab,
    PatientSocialMediasTab
  },
  mounted() {
    this.patient_modal = new bootstrap.Modal('#patient_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const patientSchema = yup.object().shape({
      company: yup.number().required().label('Empresa')
        .typeError("${label} é obrigatório"),
      name: yup.string().required().label('Nome'),
      email: yup.string().required().email().label('Email'),
      dob: yup.string().required().label('Data de Nascimento'),
      zip_code: yup.number().required().label("CEP"),
      address_street: yup.string().required().label("Rua"),
      address_number: yup.number().required().label("Número"),
      address_neighborhood: yup.string().required().label("Bairro"),
      address_complement: yup.string().label("Complemento"),
      address_city: yup.string().required().label("Cidade"),
      address_state: yup.string().required().label("Estado"),
      address_country: yup.string().required().label("País"),
      rg: yup.string().required().label('RG'),
      cpf: yup.string().required().label('CPF'),
      sex: yup.string().required().label('Gênero'),
      occupation: yup.string().required().label('Ocupação'),
      clinic: yup.number().required().label('Clínica')
        .typeError("${label} é obrigatório"),
      health_type: yup.string().required().label('Assistência Médica'),
      healthcare_operator_id: yup.string().label('Operadora de Saúde')
        .when('health_type', {
          is: HealthTypes.HEALTH_INSURANCE,
          then: s => s.required()
        }),
      sus_card: yup.string().label('N° da carteirinha do SUS')
        .when('health_type', {
          is: HealthTypes.SUS,
          then: s => s.required()
        }),
      health_insurance: yup.string().label('Plano de Saúde')
        .when('health_type', {
          is: HealthTypes.HEALTH_INSURANCE,
          then: s => s.required()
        }),
      social_medias: yup.array().of(
        yup.object().shape({
          name: yup.string().required().label("Nome"),
          social_media: yup.string().required().label("Mídia Social"),
        })
      ),
    });

    const responsibleSchema = yup.object().shape({
      responsible_name: yup.string().required().label('Nome'),
      responsible_email: yup.string().email().required().label('Email'),
      responsible_dob: yup.string().required().label('Data de Nascimento'),
      responsible_zip_code: yup.number().required().label("CEP"),
      responsible_address_street: yup.string().required().label("Rua"),
      responsible_address_number: yup.string().required().label("Número"),
      responsible_address_neighborhood: yup.string().required().label("Bairro"),
      responsible_address_complement: yup.string().label("Complemento"),
      responsible_address_city: yup.string().required().label("Cidade"),
      responsible_address_state: yup.string().required().label("Estado"),
      responsible_address_country: yup.string().required().label("País"),
      responsible_rg: yup.string().required().label('RG'),
      responsible_cpf: yup.string().required().label('CPF'),
      responsible_sex: yup.string().required().label('Gênero'),
      responsible_occupation: yup.string().required().label('Ocupação'),
    });

    return {
      patientSchema,
      responsibleSchema,
      loading: false,
      state: getState(null),
    }
  },
  computed: {
    ...mapState('patients', {
      open: state => state.form.open,
      patient: state => state.form.patient,
      title: state => state.form.patient?.name ?? "Novo Paciente",
    }),
    
    schema() {
      if (this.checkMinor()) {
        return this.patientSchema
          .concat(this.responsibleSchema);
      }
      return this.patientSchema;
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openPatientModal();
      else this.closePatientModal();
    },
  },
  async created() {
    try {
      await this.$store.dispatch("companies/getOptions");
      await this.$store.dispatch("companies/getClinics");
      await this.$store.dispatch("healthcareOperators/getHealthcareOperators");
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    checkMinor() {
      return moment().diff(this.state.dob, 'years') < 18;
    },
    async handleSubmit() {
      this.loading = true
      try {
        if (this.patient)
        await this.$store.dispatch('patients/updatePatient', this.state);
        else 
        await this.$store.dispatch('patients/addPatient', this.state);
        if (this.error == null){
          showSwal.methods.showSwal({
              type:"success",
              message: "Paciente cadastrado com sucesso!",
              timer: 5000
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('patients/stopEditing');
    },
    openPatientModal() {
      this.patient_modal.show()
    },
    closePatientModal() {
      this.patient_modal.hide()
    },
    resetForm() {
      this.state = getState(this.patient)
      this.$refs.form.resetForm();
    },
  },
};
</script>