import eyeDiseasesService from "../services/eyeDiseases.service";

const initialState = {
  options: [],
  loading: false,
}

export const eyeDiseases = {
  namespaced: true,
  state: initialState,
  actions: {
    async getOptions({ commit }) {
      commit("SET_LOADING", true);
      const response = await eyeDiseasesService.getOptions();
      commit("SET_OPTIONS", response);
    },
  },
  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    resetState(state) {
      state.options = [];
      state.loading = false;
    }
  },
}
