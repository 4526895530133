import api from "./axios";
import VueJwtDecode from 'vue-jwt-decode'

export default {
  async login(user) {
    const response = await api.post("/login", user);
    const token = response.data.access_token;

    if (token) {
      localStorage.setItem("userF", JSON.stringify(token));
    }

    return this.parseToken();
  },

  async logout() {
    try {
      await api.post("/logout");
    } finally {
      localStorage.removeItem("userF");
    }
  },

  async register(user) {
    const response = await api.post("/register", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },

  parseToken() {
    try {
      const token = JSON.parse(localStorage.getItem('userF'))
      const data = VueJwtDecode.decode(token);

      return {
        loggedIn: true,
        isAdmin: data.scopes.includes('admin'),
        isTech: data.scopes.includes('tecnico'),
        readOnly: data.scopes.includes('read-only'),
      };
    } catch (error) {
      return {
        loggedIn: false,
        isAdmin: null,
        isTech: null,
        readOnly: null
      }
    }
  },
  getToken(){
    try {
      return JSON.parse(localStorage.getItem('userF'));
    } catch (error) {
      return null;
    }
  }
};