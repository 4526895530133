<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <label for="equipament_type">Tipo de Equipamento</label>
    <soft-select-search
      id="equipament_type"
      name="equipament_type"
      v-model="state.equipament_type"
      :options="EquipamentsTypesOptions"
      placeholder="Selecione o tipo de equipamento"
    />

    <label>Marca do Equipamento</label>
    <soft-field
      id="mark"
      v-model="state.mark"
      type="text"
      placeholder="Qual a marca do equipamento?"
      name="mark"
    />

    <label>Modelo</label>
    <soft-field
      id="model"
      v-model="state.model"
      type="text"
      placeholder="Qual o modelo do seu equipamento?"
      name="model"
    />

    <label>Número de Série</label>
    <soft-field
      id="serial_number"
      v-model="state.serial_number"
      type="text"
      placeholder="Qual o número de série do seu equipamento?"
      name="serial_number"
    />

    <label>Número de Ativo</label>
    <soft-field
      id="active_number"
      v-model="state.active_number"
      type="text"
      placeholder="Qual número de ativo do dispositivo"
      name="active_number"
    />

    <label for="image">Upload de Imagem</label>
    <div>
      <input
        type="file"
        accept="image/*"
        @change="handleImageUpload"
        ref="equipamentImageUpload"
      />
      <img
        v-if="state.imagePreview"
        :src="state.imagePreview"
        class="preview-image mt-2"
        alt="Prévia da Imagem"
        style="max-width: 100%; height: auto;"
      />
    </div>

    <label for="insurance">Upload da data de retirada</label>
    <div>
      <input
        type="file"
        accept="image/*"
        @change="handleInsuranceUpload"
        ref="insuranceImageUpload"
      />
      <img
        v-if="state.insuranceImagePreview"
        :src="state.insuranceImagePreview"
        class="preview-image mt-2"
        alt="Prévia do Seguro"
        style="max-width: 100%; height: auto;"
      />
    </div>

    <label for="obs">Observações</label>
    <soft-field
      class="custom-label"
      id="obs"
      v-model="state.obs"
      type="text"
      placeholder="Observações Gerais"
      name="obs"
    />
  </div>
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import formModalMixin from "../../mixins/form-modal-mixin";
import SoftField from "../../components/SoftField.vue";
import { EquipamentsTypesOptions } from "../../constants/EquipamentsTypes";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

export default {
  name: "EquipamentsDataTab",
  props: ["modelValue"],
  mixins: [formModalMixin('equipaments')],
  components: {
    SoftField,
    SoftSelectSearch,
  },
  data() {
    return {
      EquipamentsTypesOptions,
    };
  },
  computed: {
    state: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.state.imagePreview = URL.createObjectURL(file);
      }
    },
    handleInsuranceUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.state.insuranceImagePreview = URL.createObjectURL(file);
      }
    },
  },
};
</script>
