import patientsService from "../services/patients.service"
import { parseFormError } from "../services/utils";

const initialState = {
  patients: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total:0,
  query: "",
  form: {
    open: false,
    patient: null,
    error: null
  }
}

export const patients = {
  namespaced: true,
  state: initialState,
  actions: {
    async getPatients({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await patientsService.getPatients(state.page, state.perPage);
      console.log(response)
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);

    },
    async findPatients({ commit, state }, query) {
      commit("SET_QUERY", query);
      commit("SET_LOADING", true);
      const response = await patientsService.findPatients(query, state.page, state.perPage);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({commit, dispatch, state}, page){
      if (page >=1 && page <= state.lastPage){
        commit("SET_PAGE", page);
        dispatch("getPatients")
      }

    },
    changePerPage({commit, dispatch}, perPage){
      commit("SET_PER_PAGE", perPage)
      dispatch("getPatients")
    },
    startAdding({ commit }) {
      const value = { open: true, patient: null };
      commit("SET_FORM", value);
    },
    async addPatient({ commit, dispatch }, patient) {
      try {
        await patientsService.addPatient(patient);
        dispatch("stopEditing");
        dispatch("getPatients");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async startEditing({ commit }, patient) {
      const value = { open: true, patient: patient };
      commit("SET_FORM", value);
    },
    async updatePatient({ commit, dispatch }, patient) {
      try {
        await patientsService.updatePatient(patient);
        dispatch("stopEditing");
        dispatch("getPatients");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, patient: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_USERS(state, patients) {
      state.patients = patients
      state.loading = false
    },
    SET_PAGE(state, page){
      state.page = page
    },
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_TOTAL(state, total){
      console.log(total)
      state.total = total
    },
    SET_PER_PAGE(state, per_page){
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page){
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, patient }) {
      state.form.open = open;
      state.form.patient = patient;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.patients = [];
      state.loading = false;
      state.form = {
        open: false,
        patient: null,
      };
    }
  }
}
