const HealthTypes = {
    PARTICULAR: "particular",
    SUS: "sus",
    HEALTH_INSURANCE: "health_insurance",
}

export const HealthTypesName = {
    "particular": "Particular",
    "sus": "SUS",
    "health_insurance": "Plano de Saúde"
}

export default HealthTypes;

export const HealthTypesOptions =
    Object.values(HealthTypes).map(value => ({
        value: value, 
        label: HealthTypesName[value],
    }));
