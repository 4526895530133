import { mapState } from "vuex";
import showSwal from "./showSwal";

export default (store) => ({
    computed: {
        ...mapState(store, {
            error: state => state.form.error,
        }),
    },
    watch: {
        error(newError) {
            if (newError) {
                showSwal.methods.showSwal({
                    type: "error",
                    message: newError,
                    timer: 5000
                });
            }
        }
    }
})