const CompanyDepartments = {
    COMERCIAL: 1,
    FINANCE: 2,
    DIRECTORSHIP: 3,
    HUMAN_RESOURCES: 4
}

export const CompanyDepartmentNames = {
    1: "Comercial",
    2: "Financeiro",
    3: "Diretoria",
    4: "RH"
}

export default CompanyDepartments;

export const CompanyDepartmentOptions =
    Object.values(CompanyDepartments).map(value => ({
        value: value, 
        label: CompanyDepartmentNames[value],
    }));